export const captureException = (
	error: unknown,
	context?: Record<string, any>,
) => {
	if (window.Sentry) {
		window.Sentry.captureException(error, {
			extra: context,
		});
	}
};

export const captureMessage = (
	message: string,
	context?: Record<string, any>,
) => {
	if (window.Sentry) {
		window.Sentry.captureMessage(message, {
			extra: context,
		});
	}
};
